import ExcelJS from 'exceljs';
import download from '../../../../images/menu/download.png';
import '../report.css';
import moment from 'moment';
import { PRODUCTION_URL } from '../../../../constants';
import { useEffect, useState } from 'react';
import { saveAs } from 'file-saver';

const checkDateEmpty = (date_str) => {
  if (date_str !== '-') {
    return moment(date_str).format('DD/MM/YYYY');
  }
  return '-';
};

const ExcelCandidatesReport = ({ data }) => {
  const [report, setReport] = useState([]);

  useEffect(() => {
    if (data === undefined) return;
    setReport(
      data.map((d) => {
        let link = `${PRODUCTION_URL}/candidatos/${d['candidate_id']}`;
        d['Fecha de Nacimiento'] = moment(d['Fecha de Nacimiento']).format('YYYY-MM-DD');
        d['Fecha 1er entrevista realizada'] = checkDateEmpty(d['Fecha 1er entrevista realizada']);
        d['Fecha estado actual'] = checkDateEmpty(d['Fecha estado actual']);
        d['Fecha 2da entrevista realizada'] = checkDateEmpty(d['Fecha 2da entrevista realizada']);
        d['Fecha 3ra  entrevista realizada'] = checkDateEmpty(d['Fecha 3ra  entrevista realizada']);
        d['Fecha de Envío de Propuesta'] = checkDateEmpty(d['Fecha de Envío de Propuesta']);
        d['Fecha de Aceptación de Propuesta'] = checkDateEmpty(d['Fecha de Aceptación de Propuesta']);
        d['Fecha Psicotécnico'] = checkDateEmpty(d['Fecha Psicotécnico']);
        d['Fecha Examen médico'] = checkDateEmpty(d['Fecha Examen médico']);
        d['link al perfil'] = link;
        return d;
      })
    );
  }, [data, setReport]);

  const generateExcel = async () => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Candidatos');

    const columns = [
      { header: 'Nombre', key: 'Nombre' },
      { header: 'DNI', key: 'DNI' },
      { header: 'Teléfono', key: 'Teléfono' },
      { header: 'Fecha de Nacimiento', key: 'Fecha de Nacimiento' },
      { header: 'Email', key: 'Email' },
      { header: 'Educación', key: 'Educación' },
      { header: 'Universidad', key: 'Universidad' },
      { header: 'Título', key: 'Título' },
      { header: 'Empresa', key: 'Empresa' },
      { header: '¿Cómo encontró esta postulación?', key: 'Origen' },
      { header: 'Id Busqueda', key: 'Id Busqueda' },
      { header: 'Busqueda', key: 'Busqueda' },
      { header: 'Recomendado por', key: 'Recomendado por' },
      { header: 'Hiring Manager', key: 'Hiring Manager' },
      { header: 'Director', key: 'Director' },
      { header: 'Candidato Resaltado', key: 'Candidato Resaltado' },
      { header: 'Presentado por', key: 'Presentado por' },
      { header: 'Fecha 1er entrevista realizada', key: 'Fecha 1er entrevista realizada' },
      { header: 'Status', key: 'status' },
      { header: 'Fecha 2da entrevista realizada', key: 'Fecha 2da entrevista realizada' },
      { header: 'Fecha 3ra  entrevista realizada', key: 'Fecha 3ra  entrevista realizada' },
      { header: 'Fecha de Envío de Propuesta', key: 'Fecha de Envío de Propuesta' },
      { header: 'Fecha de Aceptación de Propuesta', key: 'Fecha de Aceptación de Propuesta' },
      { header: 'Fecha Psicotécnico', key: 'Fecha Psicotécnico' },
      { header: 'Status Psicotécnico', key: 'status_psi' },
      { header: 'Fecha Examen médico', key: 'Fecha Examen médico' },
      { header: 'Area', key: 'Area' },
      { header: 'Sector', key: 'Sector' },
      { header: 'Estado actual', key: 'Status' },
      { header: 'Perfil', key: 'perfil' },
      { header: 'Motivo No Avanza', key: 'not_advance_attributes' },
      { header: 'Motivo Rechazo', key: 'Motivo de Rechazo' },
      { header: 'Fecha estado actual', key: 'Fecha estado actual' },
      { header: 'link al perfil', key: 'link al perfil' },
    ];

    worksheet.columns = columns;
    report.forEach((row) => {
      const newRow = worksheet.addRow(row);
      newRow.getCell('link al perfil').value = { text: 'Ver perfil', hyperlink: row['link al perfil'] };
    });

    worksheet.columns.forEach((column) => {
      let maxLength = 0;
      column.eachCell({ includeEmpty: true }, (cell) => {
        const cellValue = cell.value ? cell.value.toString() : '';
        maxLength = Math.max(maxLength, cellValue.length);
      });
      column.width = maxLength + 2;
    });

    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    saveAs(blob, 'Candidatos.xlsx');
  };

  return (
    <button className='boton-descargar-excel' onClick={generateExcel}>
      <img src={download} width='20px' height='20px' alt='download' />
    </button>
  );
};

export default ExcelCandidatesReport;